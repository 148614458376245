<section class="main-content" *ngIf="user">
  <app-header
    class="main-content__header"
    [user]="user"
    (openMenuEvent)="openMenu()"
    (logoutEvent)="logout()"
  ></app-header>
  <app-menu
    class="main-content__menu"
    (navigatorEvent)="navigator($event)"
  ></app-menu>
</section>

<section class="main-content">
  <router-outlet></router-outlet>
</section>
