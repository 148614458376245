<div class="wrapper">
  <div class="header">
    <span class="header__logo">
      <img
        width="150px"
        height="50px"
        class="account-wrapper__image"
        src="assets/decoris.PNG"
        alt="image"
    /></span>
    <span class="header__spacer"></span>
    <span class="header__login-name"
      >{{ user?.firstName }} {{ user?.lastName }}</span
    >
    <button
      class="header__button-account-circle"
      mat-icon-button
      aria-label="Example icon-button with heart icon"
      (click)="openMenu()"
    >
      <mat-icon class="header__icon-account-circle">account_circle</mat-icon>
    </button>
    <button
      *ngIf="showLogout"
      class="header__button-logout"
      mat-icon-button
      aria-label="Example icon-button with heart icon"
      (click)="logout()"
    >
      <mat-icon class="header__icon-logout">logout</mat-icon>
    </button>
  </div>
</div>
